<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <!-- <b-form> -->
        <b-row>
          <b-col md="2">
            <b-form-group>
              <Validation-provider
                name="type_id"
                v-slot="{ errors }"
                vid="type"
                rules="required"
              >
                <v-select
                  id="contact-through"
                  placeholder="Choose a type"
                  v-model="type"
                  :options="typeOption"
                  :reduce="(medium) => medium.value"
                  label="name"
                />
                <small v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</small>
                <label v-else class="text-center">Type</label>
              </Validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Keyword"
                rules="required"
              >
                <b-form-input
                  v-model="keyword"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="Enter your niche or keyword"
                  autocomplete="off"
                />
                <small v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</small>
                <label v-else class="text-center">Keyword</label>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="location"
                rules="required"
              >
                <v-select
                  id="location"
                  ref="locationInput"
                  placeholder="Enter location"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
                  @search="onInputChange"
                  v-model="searchQuery"
                  :options="locationOptions"
                  :reduce="(medium) => medium.value"
                  label="text"
                />
                <small v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</small>
                <label v-else class="text-center">Location</label>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                vid="radius"
                name="radius"
                placeholder="Choose a Type"
                rules="required"
              >
                <v-select
                  id="contact-through"
                  v-model="radius"
                  :options="selectRadius"
                  :reduce="(medium) => medium.value"
                  placeholder="Radius"
                  label="text"
                />
                <small v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</small>
                <label v-else class="text-center">Radius</label>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <!-- loading button -->
            <template v-if="isFormSubmitLoading">
              <b-button variant="secondary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <template v-else>
              <b-button
                variant="secondary"
                type="submit"
                @click.prevent="validationForm"
                class="text-center"
              >
                Submit
              </b-button>
            </template>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>

    <br />
    <!--    Filter Options Button Group-->
    <b-row v-if="originalData.length > 0">
      <!-- <b-row> -->

      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col xs="6" sm="6" md="3" lg="3" cols="3">
              <b-form-checkbox
                v-model="filterCheckbox"
                :value="{ rating: '4.0' }"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                Rating 4 or Less
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ business_status: 'OPERATIONAL' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                Status Operational
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ website: 'website' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
                name="website"
              >
                With Website
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ nowebsite: 'nowebsite' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
                name="nowebsite"
              >
                No Website
              </b-form-checkbox>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ phone: 'phone' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                With Phone
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ nophone: 'nophone' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                No Phone
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ email: 'email' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                With Email
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ noemail: 'noemail' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                No Emails
              </b-form-checkbox>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ phoneoremail: 'phoneoremail' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                Phone Or Email
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ claimed: 'claimed' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                Claimed
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ unclaimed: 'unclaimed' }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                Unclaimed
              </b-form-checkbox>
            </b-col>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ reviews100ormore: 100 }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                100 Reviews or More
              </b-form-checkbox>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col xs="6" sm="6" md="3" lg="3">
              <b-form-checkbox
                :value="{ reviews100orless: 100 }"
                v-model="filterCheckbox"
                @change="filterUsingCheckbox($event)"
                switch
                inline
              >
                100 Reviews or Less
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!--    End of Filter Button Group-->

    <b-row>
      <b-col v-if="list.length > 0" cols="4">
        <span class="btn bg-primary text-light"
          >Total Leads: {{ list.length }} - Checked: {{ checked.length }}</span
        >
      </b-col>
      <b-col v-if="demoData.length != 0" cols="4"
        ><span class="btn bg-primary text-light"
          >Total Leads: {{ demoData.length }} - Checked:
          {{ checked.length }}</span
        ></b-col
      >
      <b-col v-if="list.length > 0" cols="8" class="text-right">
        <span @click="downloadFile()" class="btn bg-primary text-light"
          >Download Excel</span
        >
      </b-col>
      <b-col cols="4">
        <button
          v-if="checked.length > 0"
          class="btn btn-primary floating-btn"
          @click="showModal(checked)"
          v-b-tooltip.hover
          title="Add all checked lead to campaign"
        >
          <i class="fa fa-plus"> Add</i>
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        lg="6"
        xs="12"
        v-for="(item, index) in list"
        v-bind:key="item.id"
        class="mt-4"
      >
        <b-card style="margin-bottom: -1rem !important">
          <h4>
            {{ item.name ? item.name : "Not Available" }}
            <b-badge variant="primary">
              {{ item.claimed ? item.claimed : "Not Claimed" }}
            </b-badge>
            <b-form-checkbox
              class="float-right"
              :value="item"
              v-model="checked"
            >
            </b-form-checkbox>
          </h4>

          <hr />
          <b-row>
            <b-col md="6" lg="4" xs="12">
              <div class="embed-responsive embed-responsive-1by1">
                <iframe
                  v-if="item.place_id"
                  :src="getMapEmbedUrl(item.place_id)"
                  class="embed-responsive-item"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </b-col>
            <b-col md="6" lg="7" xs="12">
              <b-row>
                <b-col lg="10">
                  <div class="item-rating pull-left">
                    {{ item.rating ? item.rating : "0" }} <b>Rating:</b>
                    <ul class="unstyled-list list-inline">
                      <li
                        v-for="star in 5"
                        :key="star"
                        class="ratings-list-item"
                        :class="{ 'ml-25': star - 1 }"
                      >
                        <feather-icon
                          icon="StarIcon"
                          size="16"
                          :class="[
                            { 'fill-current': star <= item.rating },
                            star <= item.rating ? 'text-warning' : 'text-muted',
                          ]"
                        />
                      </li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <feather-icon icon="MailIcon" class="mr-50" />
                  <span><b>Email:</b></span>
                  <a href="">{{ item.email ? item.email : "Not Available" }}</a>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <feather-icon icon="PhoneIcon" class="mr-50" />
                  <span><b>Phone:</b></span>
                  {{
                    item.international_phone_number
                      ? item.international_phone_number
                      : "Not Available"
                  }}
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="12">
                  <feather-icon icon="GlobeIcon" class="mr-50" />
                  <span><b>Website:</b></span>
                  {{ item.website ? item.website : "Not Available" }}
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="12">
                  <feather-icon icon="MapPinIcon" class="mr-50" />
                  <span><b>Address:</b></span>
                  {{ item.vicinity ? item.vicinity : "Not Available" }}
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="12">
                  <feather-icon icon="UsersIcon" class="mr-50" />
                  <span><b>Reviews:</b></span>
                  {{
                    item.user_ratings_total
                      ? item.user_ratings_total
                      : "Not Available"
                  }}
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <feather-icon icon="BriefcaseIcon" class="mr-50" />
                  <span><b>Business Status:</b></span>
                  {{
                    item.business_status
                      ? item.business_status
                      : "Not Available"
                  }}
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <b-button
                    class="btn btn-primary btn-sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    v-model="item.place_id"
                    v-on:click="showModal(item)"
                    ><span class="d-block"
                      ><i class="fa fa-plus"></i> Save Campaign</span
                    >
                  </b-button>
                </b-col>
                <b-col lg="6">
                  <feather-icon
                    style="margin-top: 2px"
                    height="24px"
                    width="24px"
                    v-if="item.campaign"
                    v-b-tooltip.hover.top="item.campaign"
                    icon="CheckSquareIcon"
                    class="mr-50"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <br /><br />

    <div v-if="nextPageButton">
      <template v-if="isFormSubmitLoading">
        <b-button variant="primary" disabled>
          <b-spinner small />
          Loading...
        </b-button>
      </template>

      <template v-else>
        <b-button variant="primary" @click="validationForm"> Next > </b-button>
      </template>
    </div>

    <b-modal
      id="modal-designation-form"
      centered
      :title="modelType == 'editModel' ? 'Edit Designation' : 'Add in Campaign'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="designationValidation">
        <b-form v-on:submit.prevent="validationFormModal">
          <!-- select Employee Type-->
          <b-form-group>
            <Validation-provider
              name="campaignId"
              v-slot="{ errors }"
              vid="campaignId"
              rules="required"
            >
              <v-select
                id="employee-type-id"
                placeholder="Choose a campaign"
                v-model="campaignId"
                :options="campaignOption"
                :reduce="(medium) => medium.id"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </Validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isDesignationFormSubmitLoading">
            <b-button class="float-right" variant="secondary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right btn btn-sm btn btn-sm mr-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
            >
              Submit
            </b-button>

            <b-button
              class="float-right btn btn-sm mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              v-on:click="showAddCampaignModal"
            >
              Create Campaign
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-users-form"
      centered
      :title="modelType == 'editModel' ? 'Edit Campaign' : 'Create Campaign'"
      hide-footer
      @hidden="hiddenModalCampaign"
      no-close-on-backdrop
    >
      <validation-observer ref="usersValidation">
        <b-form v-on:submit.prevent="validationFormCampaign">
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <b-form-group label="Name" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  :rules="`${
                    modelType == 'editModel' ? '' : 'required'
                  }|max:255`"
                >
                  <b-form-input
                    id="name"
                    type="text"
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    name="name"
                    placeholder="Enter Campaign Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <!-- loading button -->
              <template v-if="isUserFormSubmitLoading">
                <b-button class="float-right" variant="secondary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              <!-- submit button -->
              <template v-else>
                <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                >
                  Submit
                </b-button>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable comma-dangle */

import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BCard,
  BCardCode,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BForm,
  BRow,
  BCol,
  BButton,
  BIconGoogle,
  BSpinner,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, confirmed, password } from "@validations";
import axios from "axios";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import bodyParser from "body-parser";
import list from "echarts/src/data/List";
import * as util from "util";
import exportFromJSON from "export-from-json";
import { mapGetters } from "vuex";

export default {
  name: "LeadGenerator",
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BRow,
    BCol,
    BButton,
    StatisticCardVertical,
    BSpinner,
    BBadge,
    VBTooltip,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapGetters({
      user: "userModule/getUser",

      permissions: "userModule/getPermissions",
    }),
  },
  data() {
    return {
      filterCheckbox: [],
      placeIdsForDetailsShow: [],
      checked: [],
      demoData: [],
      location: "",
      center: "",
      currentPlace: null,
      markers: [],
      places: [],
      keyword: "",
      type: "",
      radius: "",
      lat: "",
      locationVal: [],
      specificItemLeadDetails: {},

      lng: "",
      campaignId: "",
      campaignOption: [],
      typeOption: [],
      Option: [],
      isDesignationFormSubmitLoading: false,
      isUserFormSubmitLoading: false,
      isFormSubmitLoading: false,

      nextPageButton: "",

      selectTypeOption: [
        {
          value: "accounting",
          text: "Accounting",
        },
        { value: "gym", text: "GYM" },
        { value: "lawyer", text: "Lawyer" },
        { value: "restaurant", text: "Restaurant" },
        { value: "school", text: "School" },
        { value: "college", text: "College" },
        { value: "hospital", text: "Hospital" },
        { value: "parlour", text: "Parlour" },
      ],
      selectRadius: [
        { value: 5000, text: "5 km" },
        { value: 10000, text: "10 km" },
        { value: 20000, text: "20 km" },
      ],
      posts: [],
      list: [],
      type_name: "",
      modelType: "",
      referenceId: "",
      filteredData: [],
      originalData: [],
      searchQuery: "",
      results: [],
      errors: [],
      selectedPlace: null,
      showSelect: false,

      locationOptions: [],
    };
  },
  watch: {
    searchQuery: function (newvalue, oldvalue) {
      if (newvalue && newvalue != oldvalue) {
        this.list = [];

        this.linkClicked(newvalue);
      }
    },
    list(newValue, oldValue) {
      if (newValue != oldValue) {
        this.scrapeEmail(newValue);
      }
    },

    type(newValue, oldValue) {
      if (newValue != oldValue) {
        this.list = [];
      }
    },
    keyword(newValue, oldValue) {
      if (newValue != oldValue) {
        this.list = [];
      }
    },
    radius(newValue, oldValue) {
      if (newValue != oldValue) {
        this.list = [];
      }
    },
  },
  methods: {
    getMapEmbedUrl(placeId) {
      const apiKey = this.user?.api_key;
      return `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=place_id:${placeId}`;
    },
    linkClicked(event) {
      let place_id = event;
      this.selectedPlace = place_id;

      axios;
      this.$api
        .get("/api/get-latlong", {
          params: { place_id: place_id },
        })
        .then((response) => {
          localStorage.setItem("lat", response.data["lat"]);
          localStorage.setItem("lng", response.data["lng"]);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async onInputChange(e) {
      const { data } = await this.$api.get("/api/places-autocomplete", {
        params: { query: e },
      });

      if (data?.status == "OK") {
        const results = data.predictions;
        this.locationOptions = results.map((item) => {
          return {
            value: item?.place_id,
            text: item?.description,
          };
        });
      } else if (data?.status == "REQUEST_DENIED") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: data?.error_message,
          },
        });
      }
      
    },
    downloadFile() {
      let excelData = [];
      this.list.forEach((item, index) => {
        let s = {
          Name: item.name,
          Address: item.address,
          Rating: item.rating,
          Business_Status: item.business_status,
          Total_User_Rated: item.rating,
          Website: item.website,
          Phone: item.phone,
          Email: item.email,
        };
        excelData.push(s);
      });

      const data = excelData;
      const fileName = "Leads";
      const exportType = exportFromJSON.types.xls;
      if (data) exportFromJSON({ data, fileName, exportType });
    },
    showModal(value) {
      this.referenceId = value;
      this.$bvModal.show("modal-designation-form");
    },
    addPlaceId(value) {
      const res = this.$api.post(`/api/showDetails`, {
        place_id: value,
      });

      this.detailsInfo = res;
      const details = this.list.map(function (data) {
        if (data.place_id === value) {
          /*const leadDetailsInfo = {
            website: res?.data?.data?.result?.website,
            phone_number: res?.data?.data?.result?.phone_number,
            address: res?.data?.data?.result?.adr_address,
            url: res?.data?.data?.result?.url,
          };*/
        }
        /*else {
          return data;
        }*/
      });
    },
    showAddCampaignModal(value) {
      this.$bvModal.show("modal-users-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-designation-form");
      this.resetModal();
    },
    hiddenModalCampaign() {
      this.modelType = "";
      this.$bvModal.hide("modal-users-form");
      this.resetModal();
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.name = value?.name;
      this.designationId = value?.id;
      this.campaignId = value?.employee_type_id;

      this.showModal();
    },
    resetModal() {
      this.name = "";
      this.campaignId = "";
      this.designationId = "";
    },
    resetFilter() {
      this.filterCheckbox = "";
    },

    filterUsingCheckbox(e) {
      this.filterCheckbox.filter((item, index, arr) => {
        if (item?.nowebsite) {
          arr.find((item2, index2) => {
            if (item2?.website) {
              arr.splice(index, 1);
            }
          });
        }
        if (item?.website) {
          arr.find((item2, index2) => {
            if (item2?.nowebsite) {
              arr.splice(index, 1);
            }
          });
        }

        if (item?.phone) {
          arr.find((item2, index2) => {
            if (item2?.nophone) {
              arr.splice(index, 1);
            }
          });
        }
        if (item?.nophone) {
          arr.find((item2, index2) => {
            if (item2?.phone) {
              arr.splice(index, 1);
            }
          });
        }

        if (item?.email) {
          arr.find((item2, index2) => {
            if (item2?.noemail) {
              arr.splice(index, 1);
            }
          });
        }
        if (item?.noemail) {
          arr.find((item2, index2) => {
            if (item2?.email) {
              arr.splice(index, 1);
            }
          });
        }

        if (item?.claimed) {
          arr.find((item2, index2) => {
            if (item2?.unclaimed) {
              arr.splice(index, 1);
            }
          });
        }
        if (item?.unclaimed) {
          arr.find((item2, index2) => {
            if (item2?.claimed) {
              arr.splice(index, 1);
            }
          });
        }

        if (item?.score40orless) {
          arr.find((item2, index2) => {
            if (item2?.score41ormore) {
              arr.splice(index, 1);
            }
          });
        }
        if (item?.score41ormore) {
          arr.find((item2, index2) => {
            if (item2?.score40orless) {
              arr.splice(index, 1);
            }
          });
        }

        if (item?.reviews100ormore) {
          arr.find((item2, index2) => {
            if (item2?.reviews100orless) {
              arr.splice(index, 1);
            }
          });
        }
        if (item?.reviews100orless) {
          arr.find((item2, index2) => {
            if (item2?.reviews100ormore) {
              arr.splice(index, 1);
            }
          });
        }
      });

      if (this.filterCheckbox.length != 0) {
        let filterData = this.originalData;
        this.filterCheckbox.map(function (filterItem) {
          filterData = filterData.filter(function (item) {
            let flag = true;
            if (
              filterItem?.phoneoremail &&
              !item?.website &&
              !item?.international_phone_number
            ) {
              return false;
            }
            if (
              filterItem?.business_status &&
              filterItem?.business_status != item?.business_status
            ) {
              flag = false;
            }
            if (filterItem?.website && !item?.website) {
              flag = false;
            }
            if (filterItem?.nowebsite && item?.website) {
              flag = false;
            }
            if (filterItem?.unclaimed && item?.claimed) {
              flag = false;
            }
            if (filterItem?.claimed && !item?.claimed) {
              flag = false;
            }
            if (filterItem?.noemail && item?.email) {
              flag = false;
            }
            if (filterItem?.email && !item?.email) {
              flag = false;
            }

            if (filterItem?.phone && !item?.international_phone_number) {
              flag = false;
            }
            if (filterItem?.nophone && item?.international_phone_number) {
              flag = false;
            }

            if (filterItem?.rating && filterItem?.rating < item?.rating) {
              flag = false;
            }
            if (
              filterItem?.reviews100ormore &&
              (!item?.user_ratings_total ||
                filterItem?.reviews100ormore > item?.user_ratings_total)
            ) {
              flag = false;
            }
            if (filterItem?.reviews100orless < item?.user_ratings_total ?? 0) {
              flag = false;
            }
            if (flag) {
              return item;
            }
          });
        });
        this.list = filterData;
      } else {
        this.list = this.originalData;
      }
    },
    async getLeadItems() {
      const obj = {
        type: this.type,
        keyword: this.keyword,
        lat: localStorage.getItem("lat"),
        lng: localStorage.getItem("lng"),
        radius: this.radius,
        pagetoken: this.nextPageButton ?? "",
      };
      const { data } = await this.$api.get("/api/lead-generators", {
        params: obj,
      });

      if (data.pagetoken) {
        this.nextPageButton = data.pagetoken;
      } else {
        this.nextPageButton = "";
      }

      if (data.data.length == 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Not Found",
            icon: "BellIcon",
            variant: "warning",
            text: "No result found",
          },
        });
      }

      const newArray = [];

      await Promise.all(
        data.data.map(async (item) => {
          const { data } = await this.$api.get("/api/showDetails", {
            params: { place_id: item.place_id },
          });
          newArray.push(data.data);
        })
      );

      //Check if a Lead Exists In a Campaign
      await Promise.all(
        newArray.map(async (item) => {
          const response = await this.$api.get(
            "/api/check-lead/" + item.place_id
          );
          if (response.data.data != "") {
            item.campaign_id = response.data.data.campaign_id;
            item.campaign = response.data.data.campaign;
          }
        })
      );

      //Store to another variable
      this.originalData = newArray;

      console.log(this.originalData);

      return newArray;
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.isFormSubmitLoading = true;
          const leadData = await this.getLeadItems();

          leadData.map((item) => {
            this.list.push(item);
          });
        }

        this.isFormSubmitLoading = false;
      });
    },

    async scrapeEmail(data) {
      data.map(async (item) => {
        const { data } = await this.$api.get("/api/scrapeEmail", {
          params: { website: item.website },
        });
        item.email = data.data;
      });
    },

    validationFormModal() {
      this.$refs.designationValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isDesignationFormSubmitLoading = true;
              await this.$api.put(`/api/designations/${this.designationId}`, {
                employee_type_id: this.campaignId,
              });
              this.isDesignationFormSubmitLoading = false;
              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Designation successfully Updated",
                },
              });
            } else {
              this.isDesignationFormSubmitLoading = true;
              if (this.checked.length > 0) {
                await this.$api.post("/api/store-multiple-lead", {
                  reference_id: this.referenceId,
                  campaign_id: this.campaignId,
                });
              } else {
                await this.$api.post("/api/store-lead", {
                  reference_id: this.referenceId,
                  //reference_id: this.referenceId.place_id,
                  campaign_id: this.campaignId,
                });
              }

              this.isDesignationFormSubmitLoading = false;
              this.hiddenModal();
              this.resetModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Lead added to campaign successfully",
                },
              });
            }
          } catch (error) {
            this.isDesignationFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.designationValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    validationFormCampaign: async function () {
      this.$refs.usersValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isUserFormSubmitLoading = true;
              await this.$api.put(`/api/campaigns/${this.userId}`, {
                name: this.name,
              });
              this.isUserFormSubmitLoading = false;
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Campaign successfully Updated",
                },
              });
            } else {
              this.isUserFormSubmitLoading = true;
              let response = await this.$api.post("/api/campaigns", {
                name: this.name,
              });

              //push the response data to the campaign list
              this.campaignOption.push({
                id: response.data.data.id,
                name: response.data.data.name,
              });

              //call the api to store the leads
              if (this.checked.length > 0) {
                await this.$api.post("/api/store-multiple-lead", {
                  reference_id: this.referenceId,
                  campaign_id: response.data.data.id,
                });
              } else {
                await this.$api.post("/api/store-lead", {
                  reference_id: this.referenceId,
                  //reference_id: this.referenceId.place_id,
                  campaign_id: response.data.data.id,
                });
              }

              this.hiddenModalCampaign();
              this.hiddenModal();
              this.resetModal();
              //this.checked = [];
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Campaign successfully Created",
                },
              });
              //set the loading to false
              this.isUserFormSubmitLoading = false;
            }
          } catch (error) {
            this.isUserFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.usersValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        /*const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };*/
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function () {
      function getPosition(position) {}
    },

    async getCampaignsList() {
      return await this.$api.get("api/campaigns/all");
    },
    async getLeadTypeItems() {
      return await this.$api.get("api/lead-types/all");
    },
  },

  async created() {
    try {
      const [campaigns] = await Promise.all([this.getCampaignsList()]);

      this.campaignOption = (campaigns?.data?.data || []).map((item) => {
        return {
          name: item?.name,
          id: item?.id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }

    try {
      const [types] = await Promise.all([this.getLeadTypeItems()]);

      this.typeOption = (types?.data?.data || []).map((item) => {
        return {
          name: item?.name,
          value: item?.name,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  mounted() {
    var location = [];

    var autocomplete;
    var id = "location";

    /* autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(id),
      {
        types: ["geocode"],
      }
    ); */

    /* google.maps.event.addListener(autocomplete, "place_changed", function () {
      const place = autocomplete.getPlace();
      let location = [];
      location["lat"] = place.geometry.location.lat();
      location["lng"] = place.geometry.location.lng();

      this.lat = location["lat"];
      this.lng = location["lng"];

      this.$store.dispatch("userModule/setLocation", {
        locationVal,
            });

      //localStorage.setItem("lat", this.lat);
      //localStorage.setItem("lng", this.lng);

    }); */

    /* TODO
    console.log(this.$store.userModule.state.location);
    this.geolocate();

     function initialize() {
        var input = document.getElementById('autocomplete');
        var autocomplete = new google.maps.places.Autocomplete(input);
          google.maps.event.addListener(autocomplete, 'place_changed', function () {
              var place = autocomplete.getPlace()
          })
      }
      google.maps.event.addDomListener(window, 'load', initialize);
      */
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.bg-color {
  background-color: #e4f1f7;
}

.margin-left {
  margin-left: 0 !important;
}

.floating-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 20px;
  bottom: 60px;
  z-index: 9999;
  animation: anime-7a9e3f4a 12s linear infinite;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: linear-gradient(-45deg, #ff8533, #00634c);
}

@keyframes anime-7a9e3f4a {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
